const appConfig =
{
    apiPrefix: 'https://e-facturacionsv.com/IFLOSERSA/BACK/public/api',
    locale: 'es',
    logoSrc: 'https://e-facturacionsv.com/IFLOSERSA/BACK/public/api/images/general/logo.png',
    escudoSrc: 'http://e-facturacionsv.com/IFLOSERSA/BACK/public/api/images/general/escudo.png',
    authenticatedEntryPath: '/directory/employees',
    unAuthenticatedEntryPath: '/signin',
    changePasswordEntryPath: '/change-password',
    tourPath: '/',
    enableMock: false
};

export default appConfig;
